<template>
 <content-loader
    viewBox="0 0 1300 248"
    :speed="1"
    primaryColor="#f3f3f3"
    secondaryColor="#cfcfcf"
  >
    <rect x="0" y="0" rx="0" ry="0" width="313" height="224"/>
    <rect x="40" y="232" rx="0" ry="0" width="233" height="16"/>

    <rect x="329" y="0" rx="0" ry="0" width="313" height="224"/>
    <rect x="369" y="232" rx="0" ry="0" width="233" height="16"/>

    <rect x="658" y="0" rx="0" ry="0" width="313" height="224"/>
    <rect x="698" y="232" rx="0" ry="0" width="233" height="16"/>

    <rect x="987" y="0" rx="0" ry="0" width="313" height="224"/>
    <rect x="1027" y="232" rx="0" ry="0" width="233" height="16"/>
  </content-loader>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
export default {
  components: {
    ContentLoader,
  },
};
</script>