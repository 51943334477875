<template>
 <content-loader
    viewBox="0 0 1300 700"
    :speed="1"
    primaryColor="#f3f3f3"
    secondaryColor="#cfcfcf"
  >
    <rect x="0" y="0" rx="0" ry="0" width="1300" height="700"/>
  </content-loader>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
export default {
  components: {
    ContentLoader,
  },
};
</script>