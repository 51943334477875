<template>
  <div>
    <div class="">
      <div class="mx-auto max-w-[95%] sm:max-w-[90%] md:max-w-[1500px] px-2.5 lg:px-0">
        <div v-if="!ready">
          <LoadersHomePageSlider/>
        </div>
        <swiper
            class="mt-[40px] md:mt-0"
            :modules="[Navigation, Pagination, Scrollbar, A11y, Autoplay]"
            :slides-per-view="1"
            :space-between="50"
            :pagination="{ clickable: true }"
            :navigation="{ clickable: true }"
            :autoplay="{delay: 10000}"
        >
<!--          <swiper-slide>-->
<!--              <div class="h-[300px] sm:h-[450px] md:h-[600px] lg:h-[700px]">-->
<!--                <nuxt-img-->
<!--                    width="1500"-->
<!--                    height="700"-->
<!--                    preload-->
<!--                    format="webp"-->
<!--                    rel="preload"-->
<!--                    src="/gallery3.jpg"-->
<!--                    class="w-full h-full object-cover"-->
<!--                    alt="National"-->
<!--                />-->
<!--              </div>-->
<!--            </swiper-slide>-->
          <swiper-slide v-for="(slide,index) in sliderImages" :key="slide.id"
          >
            <div class="h-[300px] sm:h-[450px] md:h-[600px] lg:h-[700px]">
              <template v-if="index<1">
                <nuxt-img
                    width="1500"
                    height="700"
                    preload
                    format="webp"
                    rel="preload"
                    :src="slide.link"
                    class="w-full h-full object-cover"
                    :alt="slide.name"
                />
              </template>
              <template v-else>
                <nuxt-img
                    width="1500"
                    height="700"
                    format="webp"
                    :src="slide.link"
                    class="w-full h-full object-cover"
                    :alt="slide.name"
                />
              </template>
            </div>
          </swiper-slide>
        </swiper>
        <p
            class="
            text-center
            uppercase
            md:mt-12
            md:mb-12
            my-5
            text-4.5xl
            md:text-4.8xl
            sm:tracking-[.25em]
            font-titleSemiMediu
          "
        >
          PHOTO GALLERIES
        </p>
        <LoadersHomePhotoGalleries v-if="!ready"/>

        <div
            class="
            grid grid-cols-1
            md:grid-cols-2
            lg:grid-cols-4
            gap-4
            max-w-xs
            md:max-w-xl
            lg:max-w-full
            mx-auto
          "
        >
          <div v-for="(gallery,index) in galleries" :key="gallery" class="gallerie group">
            <div class="relative">
              <NuxtLink
                  :to="'/photo-gallery/photos/' + gallery.path"
                  class="
                  absolute
                  inset-0
                  z-10
                  bg-black
                  text-center
                  flex flex-col
                  items-center
                  justify-center
                  opacity-0
                  group-hover:opacity-100
                  font-title
                  text-3xl.5
                  bg-opacity-60
                  duration-300
                "
              >
                <p
                    class="
                    tracking-wider
                    text-white
                    border border-white
                    font-semibold
                    uppercase
                    max-w-[170px]
                    py-1.5
                    px-8
                    text-2xl
                    hover:opacity-80
                  "
                >
                  View The Gallery
                </p>
              </NuxtLink>
              <NuxtLink
                  :to="'/photo-gallery/photos/' + gallery.path"
                  class="relative"
              >
                <div class="h-56 flex content-center overflow-hidden">
                  <nuxt-img
                      width="363"
                      height="224"
                      format="webp"
                      loading="lazy"
                      :src="siteURL+'/storage/images/gallery/' +
                          gallery.path +
                          '/cover/' +
                          gallery.cover_photo"
                      class="mx-auto h-full w-full object-cover"
                      :alt="gallery.name"
                  />
                </div>
              </NuxtLink>
            </div>
            <NuxtLink
                :to="'/photo-gallery/photos/' + gallery.path"
            >
              <p class="font-semibold uppercase hover:underline hover:text-slate-600 text-center mt-1.5">
                {{ gallery.name }}
              </p>
            </NuxtLink>

          </div>
        </div>
        <div class="text-center mt-10 mb-2">
          <NuxtLink to="/photo-gallery"
                    class="text-3xl font-semibold  border border-black px-6 py-3 uppercase hover:text-white hover:bg-black hover:decoration-[0px]">
            View all
          </NuxtLink>
        </div>
      </div>
      <div class="bg-black mt-7 md:mt-20">
        <div class="flex flex-col lg:flex-row max-w-[1500px] px-6 mx-auto">
          <div class="max-w-[500px] mx-auto py-6 lg:max-w-full lg:w-3/5">
            <p
                class="
                text-white
                uppercase
                md:mb-12
                text-5xl
                tracking-widest
                font-title
              "
            >
              In The News
            </p>
            <div v-if="!ready">
              <div v-for="n in 4" :key="n">
                <LazyLoadersHomeNews/>
              </div>
            </div>
            <div v-for="newsItem in newsItems" :key="newsItem.id">
              <p class="text-themeRed font-semibold text-3xl mt-4">
               {{ new Intl.DateTimeFormat('default', {    timeZone: 'utc',month: 'short', day: 'numeric'}).format(new Date(newsItem.created_at )) }}
              </p>
              <NuxtLink
                  :to="'/news/entry/' + newsItem.entry_uri"
                  class="text-white text-2xl lg:text-4.2xl hover:text-themeRed"
              >{{ newsItem.entry_title }}
              </NuxtLink
              >
            </div>
          </div>
          <div
              class="
              w-full
              lg:w-2/5
              flex flex-col
              lg:flex-row
              items-center
              md:px-12
            "
              v-if="widget.news_active"
          >
            <NuxtLink :href="widget.url"
                      class="
                hidden
                lg:block
                custom-vertical-txt
                text-[90px] text-grayMedium
                uppercase
                font-titleSemiLight
                h-full
                relative
                text-center
                hover:opacity-75
                hover:transition delay-150 duration-300
                max-h-[710px]
              "
            >
              Featured Event
              <div class="absolute h-full w-[2px] top-0 left-[35px] bg-grayMedium"></div>
            </NuxtLink>
            <p
                class="
                text-5xl
                md:text-[70px]
                text-white
                opacity-25
                lg:hidden
                mt-12
                font-title
              "
            >
              Featured Event
            </p>
            <div class="text-center text-white" v-if="ready">
              <p class="text-base uppercase font-bold">
                {{ widget.title }}
              </p>
              <NuxtLink
                  :href="widget.url"
                  class="hover:transition delay-150 duration-300 hover:opacity-75"
              >
                <nuxt-img
                    v-if="widget.image"
                    loading="lazy"
                    width="240"
                    height="280"
                    format="webp"
                    class="mx-auto my-4"
                    :alt="widget.image"
                    :src="siteURL+'/storage/images/widget-text-image/thumbnail_cache/' +
                      widget.image"
                />
              </NuxtLink>
              <p class="max-w-xs mx-auto text-tiny">
                {{ widget.description }}
              </p>
              <NuxtLink
                  :href="widget.url"
                  class="
                  border border-white
                  py-1.5 px-3
                  font-title
                  mt-4
                  mb-4
                  hover:bg-text-grayMedium hover:border-grayMedium hover:text-grayMedium
                  hover:transition delay-300 duration-300
                  duration-300
                  font-normal
                  inline-flex
                  text-4xl
                "
              >
                READ MORE
              </NuxtLink>
            </div>
          </div>
          <div
              class="
              w-full
              lg:w-2/5
              flex flex-col
              lg:flex-row
              items-center
              md:px-12
              justify-between
            "
              v-else
          >
            <form
                ref="tokenForms"
                method="post" :action="config.siteURL+'/api'+currentIssue.url+'/'">
              <input type="hidden" name="idToken" :value="idToken">
            </form>
            <div
                @click="tokenForms.submit()"
                      class="
                      hover:cursor-pointer
                hidden
                lg:block
                custom-vertical-txt
                text-[90px] text-grayMedium
                uppercase
                font-titleSemiLight
                h-full
                relative
                text-center
                hover:opacity-75
                hover:transition delay-150 duration-300
                max-h-[710px]
              "
            >
              Current Issue
              <div class="absolute h-full w-[2px] top-0 left-[35px] bg-grayMedium"></div>
            </div>
            <p
                class="
                text-5xl
                md:text-[70px]
                text-white
                opacity-25
                lg:hidden
                mt-12
                font-title
              "
            >
              Current Issue
            </p>
            <div class="text-center text-white" v-if="ready">
              <p class="text-base uppercase font-bold">
                {{ currentIssue.definition_issue.title }}
              </p>
              <div
                  @click="tokenForms.submit()"
                  class="hover:transition  hover:cursor-pointer delay-150 duration-300 hover:opacity-75"
              >
                <img
                    width="300"
                    height="340"
                    class="mx-auto my-4"
                    :src="currentIssue.s3"
                    :alt="currentIssue.definition_issue.title"
                />
              </div>

              <p class="uppercase italic font-bold text-base">Featuring</p>
              <p class="max-w-xs mx-auto text-tiny">
                {{ currentIssue.feauturing }}
              </p>

              <div
                  @click="tokenForms.submit()"
                  class="
                   hover:cursor-pointer
                  border border-white
                  py-1.5 px-3
                  mt-4
                  mb-4
                  hover:bg-text-grayMedium hover:border-grayMedium hover:text-grayMedium
                  hover:transition delay-300 duration-100
                  duration-100
                  font-normal
                  inline-flex
                  text-4xl
                "
              >
                READ ONLINE
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mx-auto max-w-[1300px] mb-12 px-6">
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-20 ">
          <div v-for="widget in widgets" :key="widget" class="text-center max-w-[280px] mx-auto hover:opacity-70">
            <div class="max-w-xs mx-auto">
              <NuxtLink :to="widget.url?? '/'" class="hover:underline">
              <nuxt-img
                  width="154"
                  height="126"
                  format="webp"
                  loading="lazy"
                  :alt="widget.image"
                  :src="siteURL+'/storage/images/widget-text-image/thumbnail_cache/' +
                      widget.image"
                  class="mx-auto"
              />
              <p class="text-4xl font-title text-4.2xl leading-8">{{ widget.title }}</p>
              <p>
                {{ widget.description }}
              </p>
              </NuxtLink>
            </div>
          </div>
        </div>
        <p v-if="posts.length > 0"
            class="
            text-center
            uppercase
            mt-20
            mb-12
            text-5xl
            tracking-widest
            font-title
          "
        >
          On the Feed
        </p>
        <div v-if="posts.length > 0"
            class="
            grid grid-cols-1
            md:grid-cols-2
            lg:grid-cols-4
            gap-4
            mx-auto
            max-w-xs
            md:max-w-xl
            lg:max-w-full
          "
        >
          <div class="relative" v-for="(post,index) in posts">
            <NuxtLink :to="post.permalink" target="_blank"
                      class="
                absolute
                inset-0
                z-10
                bg-black
                text-center
                flex flex-col
                items-center
                justify-center
                opacity-0
                hover:opacity-80
                bg-opacity-80
                duration-300
              "
            >
              <div class="bg-white p-2 rounded-full">
                <nuxt-img
                    preload
                    width="30"
                    height="30"
                    format="webp"
                    src="/instagram_black.svg"
                    class="mx-auto w-[30px] h-[30px]"
                    alt="instagram_black"
                />
              </div>
            </NuxtLink>
            <NuxtLink :to="post.permalink" class="relative" target="_blank">
              <div class="h-64 flex content-center overflow-hidden">
                <nuxt-img
                    loading="lazy"
                    width="301"
                    height="256"
                    format="webp"
                    :src="post.url"
                    class="mx-auto h-full w-full object-cover"
                    :alt="post.url"
                />
              </div>
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {Navigation, Pagination, Scrollbar, A11y, Autoplay} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";
import {http} from "~/utils/http";
import {useHead, useRuntimeConfig} from "nuxt/app";
import {useSiteApp, useSiteURL} from "../composables/state";
import {onBeforeMount, ref} from "vue";
import { Widget } from "~/composables/nhTypes";

const config = useRuntimeConfig();
definePageMeta({
  layout: "home",
});

const newsItems = ref(null);
const galleries = ref(null);
const sliderImages = ref([]);
const sliderPath = ref(null);
const widgets = ref(null);
const widget = ref(new Widget);
const ready = ref(false);
const posts = ref([]);
let currentIssue = ref("");
const siteURL = useSiteURL();
const tokenForms = ref(null);
const idToken = useCookie("idToken");
const siteApp= useSiteApp();

const getContent = async () => {
  try {
    await http.get("/api/home").then((json) => {
      newsItems.value = json.data.news;
      galleries.value = json.data.galleries;
      sliderImages.value = json.data.slider.slider_images;
      sliderPath.value = json.data.slider.slider_path;
      let slider_imgs = [];
      sliderImages.value.forEach((item) => {
        slider_imgs.push({
          link:siteURL.value+'/storage/images/home-slider/' +
              sliderPath.value +
              '/thumbnail_cache/' +
              item.hash,
          name: item.name
        })
      })
      widget.value = json.data.widget;
      widget.value.news_active = !!widget.value.news_active;

      sliderImages.value = slider_imgs;

      widgets.value = json.data.widgets;
      currentIssue.value = json.data.current_issue;
      posts.value = json.data.posts;

      if(posts.value.length > 0){
        posts.value = posts.value.slice(0,4);
      }

      ready.value = true;
    });
  } catch (e) {
  }
};

// onBeforeMount( () => {
 await getContent();
// })
</script>
